<template>
  <el-date-picker
    v-model="ShopForm.activityJoinTime[index]"
    type="datetimerange"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    format="yyyy-MM-dd HH:mm"
    @change="handleSelectTime"
  >
  </el-date-picker>
</template>

<script>
export default {
  props: {
    ShopForm: {
      type: Object,
    },
    index: {
      type: Number,
    },
    opening_times:{
        type:Array
    }
  },
  data() {
    return {
      // 报名时间选择范围设置
      pickerJoinOptions: {
        disabledDate: (time) => {
          return this.selectableJoinDate(time);
        },
      },
    };
  },
  methods: {
    // 选择活动时间
    handleSelectTime(value) {
      if (value && value.length) {
        let start_time = value[0] / 1000;
        let end_time = value[1] / 1000;
        this.$emit('selectTime', this.index,start_time,end_time);
      } else {
        this.ShopForm.shop_start_time = this.ShopForm.shop_end_time = '';
      }
    },

    // 报名时间选择范围设置
    selectableJoinDate(time) {
      if (this.ShopForm.shop_end_time && this.ShopForm.type) {
        return time.getTime() > this.ShopForm.shop_end_time * 1000;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
</style>