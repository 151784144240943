import api from "@/base/utils/request";
// 获取店铺类型列表
export const typeList = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/index",
        method: 'post',
        data
    })
}
// 保存
export const addType = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/save",
        method: 'post',
        data
    })
}
// 迁移
export const move = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/move",
        method: 'post',
        data
    })
}
// all
export const all = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/all",
        method: 'post',
        data
    })
}
// 软删除
export const typeSoftDel = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/softDelete",
        method: 'post',
        data
    })
}
// 彻底删除
export const typeDel = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/del",
        method: 'post',
        data
    })
}
// 恢复
export const typePutBack = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/putBack",
        method: 'post',
        data
    })
}