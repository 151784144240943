<template>
  <el-dialog
    title="店铺类型"
    width="734px"
    :show-close="true"
    class="dialog-vertical"
    :visible.sync="isShow"
    @close="cancel"
    v-loading="loading"
  >
    <draggable :sort="true" group="view_index" animation="300">
      <transition-group class="tag">
        <el-tag v-for="(type, index) in TypeArr" :key="type.id">
          {{ type.name }}
        </el-tag>
      </transition-group>
    </draggable>
    <el-input
      placeholder="请输入"
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button type="primary" v-else class="button-new-tag" @click="showInput"
      >新增</el-button
    >
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable';
import { typeList, addType } from '../../api/type-list/list';
export default {
  props: ['openDialog'],
  data() {
    return {
      TypeArr: [],
      typeForm: {
        keyword: '',
        create_start_time: -1,
        create_end_time: -1,
        tab: 'normal',
        page: 1,
        page_size: 15,
        order_by: 'create_time',
        is_desc: 1,
      },
      loading: false,
      isShow: this.openDialog,
      inputVisible: false,
      inputValue: '',
    };
  },
  created() {
    this.getTypeList(this.typeForm);
  },
  methods: {
    getTypeList(data) {
      // 店铺类型
      typeList(data).then((res) => {
        this.TypeArr = res.data.data;
        this.loading = false;
      });
    },
    // 取消
    cancel() {
      this.$emit('closeDialog', false);
    },
    // 新增类型标签
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      this.loading = true;
      let inputValue = this.inputValue;
      if (inputValue) {
        let data = {
          id: '',
          name: inputValue,
          sort: 1,
        };
        addType(data).then((res) => {
          if (res.msg == '保存成功') {
            this.$message({
              message: res.msg,
              type: 'success',
            });
          }
          this.getTypeList(this.typeForm);
        });
        
      } else {
        this.loading = false;
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
  },
  watch: {
    openDialog(val) {
      this.isShow = val;
    },
  },
  components: {
    draggable,
  },
};
</script>

<style scoped>
.el-tag {
  display: inline-block;
  margin: 15px 20px;
  background-color: #aaa;
  border: 0;
  color: #fff;
  border-radius: 18px;
  font-size: 16px;
  width: 130px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.input-new-tag {
  width: 130px;
  height: 36px;
  margin-left: 20px;
}
.button-new-tag{
  margin-left: 20px;
}
</style>