import api from "@/base/utils/request";

// 获取店铺列表
export const shopList = data => {
    return api({
        url: "/admin/cyc/rights_shop/index",
        method: "post",
        data
    })
}
// 店铺类型
export const shopType = data => {
    return api({
        url: "/admin/cyc/rights_shop_category/all",
        method: 'post',
        data
    })
}

// 详情
export const shopDetail = data => {
    return api({
        url: "/admin/cyc/rights_shop/detail",
        method: "post",
        data
    })
}
// 软删除
export const shopSoftDelete = data => {
    return api({
        url: "/admin/cyc/rights_shop/softDelete",
        method: 'post',
        data,
    })
}
//彻底删除
export const shopDel = data => {
    return api({
        url: "/admin/cyc/rights_shop/del",
        method: "post",
        data
    })
}
// 恢复
export const shopPutBack = data => {
    return api({
        url:"/admin/cyc/rights_shop/putBack",
        method:'post',
        data
    })
}
// 发布
export const shopAdd = data => {
    return api({
        url: "/admin/cyc/rights_shop/save",
        method: 'post',
        data
    })
}